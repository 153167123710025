export const COMMON_MESSAGE = {
  SUCCESS: '処理が正常終了しました',
  CLEAR_CONFIRM: '編集中の内容は失われます。\nよろしいですか。',
  UPDATED: '更新が完了しました',
  ERROR_500: '予期せぬエラーが発生しました',
  VALID_ERR: 'エラー内容を修正の上、再度更新してください。',
}

export const LOGIN_MESSAGE = {
  ERR_AUTH: 'IDまたはパスワードが誤っています',
  UNMUCH_PASSWORD: '入力されたパスワードが一致しません',
  LOGIN: 'ログインしました',
  LOGOUT_CONF: 'ログアウトしますか？',
  LOGOUTED: 'ログアウトしました',
}

export const PASSWORD_MESSAGE = {
  SAME_PASSWORD: '入力されたパスワードは現在のパスワードと一致しています。',
  RESET_PASSWORD_SUCCESS: 'パスワードの再設定が完了しました。',
  FAILED_PASSWORD: 'パスワード更新が失敗しました。',
  INPUT_CODE: '確認コードを入力してください。',
  INPUT_PASSWORD: '新しいパスワードを入力してください。',
  INVALID_OLDPASSWORD: '古いパスワードが誤っています。',
  ERROR_CONFIRM_PASSWORD: 'パスワードと確認用パスワードが一致しません',
  CAPTION: '※アルファベット（大文字小文字混在）と数字を組み合わせて8～16文字で入力してください。',
}

export const API_ERROR_MESSAGE = {
  NETWORK_ERROR: '通信エラーが発生しました',
  TIMEOUT_ERROR: '通信エラーが発生しました',
  SYSTEM_ERROR: '通信エラーが発生しました。お手数ですが、再度お試しください。',
  MUTUAL_EXCLUSION: '予期せぬエラーが発生しました。もう一度操作してください。',
  PASSWORD_CHANGE: 'パスワードが変更されました。再度ログインしてください。',
  UNAUTHORIZED_ERROR: '認証に失敗しました。ログアウトします。',
}

export const API_ERROR_MESSAGE_RESPONSE = {
  SYSTEM_ERROR: 'システムエラーが発生しました。',
}

export const FAVORITE_MESSAGE = {
  SUCCESS_MYSTATION_INSERT: 'マイステーションに登録しました。',
  SUCCESS_MYSTATION_DELETE: 'マイステーションから削除しました。',
  SUCCESS_MYCAR_INSERT: 'マイカーに登録しました。',
  SUCCESS_MYCAR_DELETE: 'マイカーから削除しました。',
}
<template>
  <div>
    <simple-layouts v-if="error">
      <template v-slot:content>
        <div class="password errorpage">
          <form>
            <main class="cf password1">
              <section class="common_section reminder">
                <h2>エラーが発生しました。</h2>
                <div class="nobox">
                  <p class="body_text">
                    システムエラーが発生しました。
                    <br />ご不便とご面倒をおかけしますが、しばらく時間をおいてから再度ご利用ください。
                  </p>
                  <template v-if="!isProduction">
                    <p>エラーメッセージ : {{ error.message }}</p>
                    <p>エラー情報 : {{ info }}</p>
                    <p>エラー詳細 :</p>
                    <p>{{ error.stack }}</p>
                  </template>
                  <div class="btn_area">
                    <button type="button" class="btn_l btn_blue" @click.stop.prevent="goLogin">
                      ログイン画面へ戻る
                    </button>
                  </div>
                </div>
              </section>
            </main>
          </form>
        </div>
      </template>
    </simple-layouts>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
  import Config from '@/conf/Config'
  import ErrorTracking from '@/module/ErrorTracking'

  export default {
    data() {
      return {
        error: null,
        info: null,
      }
    },
    // vueの子孫コンポーネントで発生したエラーは検知する
    errorCaptured(err, vm, info) {
      this.$logger.error(`Error Occurred at ${window.location.href}`, {
        err,
        info,
      })
      if (window.location.host !== Config.LOCAL_HOST && this.isProduction) {
        ErrorTracking.captureException(err)
        ErrorTracking.showReportDialog()
      }
      this.error = err
      this.info = info
    },
    mounted() {},
    methods: {
      goHome() {
        this.error = null
        this.info = null
        this.$router.push('/')
      },
      goLogin() {
        console.log('ログアウト', Config.URL.LOGOUT_PATH)
        this.error = null
        this.info = null
        this.$router.push(Config.URL.LOGOUT_PATH)
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #main article {
    text-align: left;
  }
</style>

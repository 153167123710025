import { configure } from 'vee-validate'
import { localize, setLocale } from '@vee-validate/i18n'
import { MESSAGE } from '@/constants/constant.message'

configure({
  generateMessage: localize({
    jp: {
      messages: {
        required: MESSAGE.E0001,
        email: MESSAGE.WRONG_INPUT_FORMAT,
        max: MESSAGE.E0002,
        katakana: MESSAGE.E0013,
        confirmed: MESSAGE.E0034,
        betweenMinMaxLength: MESSAGE.E0035,
        passwordPattern: MESSAGE.E0047,
      },
    },
    en: {
      messages: {},
    },
  }),
})

setLocale('jp')

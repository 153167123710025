import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// add compornent for global
import ErrorBoundary from '@/components/ErrorBoundary.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Modal from '@/components/Modal.vue'
import Confirm from '@/components/Confirm.vue'
import Application from '@/views/base/Application'
import logger from '@/module/Logger'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Toaster from '@meforma/vue-toaster'
// add plugin for global
import MyApplicationPlugin from '@/module/plugin/application'
import './assets/style/style.css'
import './assets/slick/slick.css'
import './assets/slick/slick-theme.css'
import './conf/ValidationRules'
import './conf/ValidationMessagesConfig'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/ja';

/* global SENTRY_ENDPOINT */
if (process.env.NODE_ENV === 'production') {
  // console.log("main.js 1", process.env.NODE_ENV)
  logger.info('Loading... ############  Sentry integration enabled. ############')

  Sentry.init({
    dsn: SENTRY_ENDPOINT || 'https://fa810c83b6794b7e9b6c2f8c858d550a@sentry.io/1759472',
    integrations: [
      new Integrations.Vue({
        createApp,
        attachProps: true,
      }),
    ],
  })
}

let app = createApp(App)
  .component('error-boundary', ErrorBoundary)
  .component('app-header', Header)
  .component('app-footer', Footer)
  .component('app-modal', Modal)
  .component('app-confirm', Confirm)
  .use(MyApplicationPlugin)
  .use(store)
  .use(router)
  .use(VueLoading)
  .use(Toaster)
  .use(ElementPlus, { locale})
  .mixin({
    mixins: [Application],
  })

app.config.errorHandler = (err, vm, info) => {
  console.log('例外エラー検知', err, vm, info)
}

app.mount('#app')

import Config from '@/conf/Config'
import store from '@/store'
import { SESSION_MUTATION_TYPES } from '@/store/modules/session'
// import { MASTER_MUTATION_TYPES } from '@/store/modules/master';
const AmazonCognitoIdentity = require('amazon-cognito-identity-js')

/* global POOL_DATA */
const poolData = POOL_DATA || {}
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

export default class Auth {
  static getUserPool() {
    return userPool
  }

  static isLogin() {
    return userPool.getCurrentUser() != null
  }

  static getCurrentUser() {
    return userPool.getCurrentUser()
  }

  static signin(email, password) {
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    })
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: email,
      Password: password,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result)
        },

        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  static signout() {
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser != null) {
      store.commit(SESSION_MUTATION_TYPES.CLEAR_VALUES)
      // store.commit(MASTER_MUTATION_TYPES.CLEAR_VALUES);
      cognitoUser.signOut()
    }
  }

  static getSession() {
    const cognitoUser = userPool.getCurrentUser()

    return new Promise((resolve, reject) => {
      if (cognitoUser == null) resolve(false)
      cognitoUser.getSession((err, session) => {
        if (err) reject(err)
        resolve(session)
      })
    })
  }

  static changePassword(oldPassword, newPassword) {
    const cognitoUser = userPool.getCurrentUser()

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err) => {
        if (err) reject(err)
        cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
          if (error) reject(error)
          resolve(result)
        })
      })
    })
  }
  static confirmPassword(Username, ConfirmationCode, newPassword) {
    const userData = {
      Username,
      Pool: userPool,
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(ConfirmationCode, newPassword, {
        onSuccess: () => {
          resolve()
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }
  static resetPassword(Username) {
    const userData = {
      Username,
      Pool: userPool,
    }

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {},
        onFailure: (err) => {
          reject(err)
        },
        async inputVerificationCode() {
          resolve(cognitoUser)
        },
      })
    })
  }

  static getAttribute() {
    const cognitoUser = userPool.getCurrentUser()

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err) => {
        if (err) reject(err)
        cognitoUser.getUserAttributes((error, results) => {
          if (error) reject(error)
          let attribute = {}
          results.forEach(({ Name, Value }) => {
            attribute = { ...attribute, [Name]: Value }
          })
          resolve(attribute)
        })
      })
    })
  }

  static isActivate() {
    const cognitoUser = userPool.getCurrentUser()

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err) => {
        if (err) reject(err)
        cognitoUser.getUserAttributes((error, results) => {
          if (error) reject(error)
          resolve(results.some(({ Name }) => Name === Config.COGNITO.ATTRIBUTE_ACTIVATE))
        })
      })
    })
  }
}

/* eslint-disable */
import App from 'vue'
import is from 'is_js'
import _, { reject } from 'lodash'
import moment from 'moment'
import 'moment/locale/ja'
import logger from '@/module/Logger'
import store, { GETTER_TYPES } from '@/store'
import jQuery from 'jquery'

export default {
  install: (App) => {
    // 確認ダイアログ(ok / cancel)
    const confirmOpen = (msg, title, textOk, textCancel) =>
      new Promise((resolve) => {
        const payload = {
          isOpen: true,
          message: msg,
          title: title,
          isDialog: false,
          textOk: textOk ? textOk : 'OK',
          textCancel: textCancel ? textCancel : 'キャンセル'
        }
        store
          .dispatch('actionConfirmOpen', payload)
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            reject(false)
          })
      })

    // メッセージダイアログ
    const dialogOpen = (msg, title) =>
      new Promise((resolve) => {
        const payload = {
          isOpen: true,
          message: msg,
          title: title,
          isDialog: true,
          textOk: 'OK',
          textCancel: '',
        }
        store
          .dispatch('actionConfirmOpen', payload)
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            reject(false)
          })
      })

    // カスタムダイアログ
    const CustomDialogOpen = (msg, title, okHtml, textCancel) =>
      new Promise((resolve) => {
        const payload = {
          isOpen: true,
          message: msg,
          title: title,
          isDialog: true,
          customOk: okHtml,
          textOk: '',
          textCancel: textCancel ? textCancel : 'キャンセル'
        }
        store
          .dispatch('actionConfirmOpen', payload)
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            reject(false)
          })
      })
    moment.locale('ja')
    App.config.globalProperties.$moment = moment
    App.config.globalProperties.$is = is
    App.config.globalProperties.$jquery = jQuery
    App.config.globalProperties.$_ = _
    App.config.globalProperties.$logger = logger
    App.config.globalProperties.$rexev = {}
    // 確認メッセージ
    App.config.globalProperties.$_confirm = {
      confirmOpen,
      dialogOpen,
      CustomDialogOpen,
    }
    App.config.globalProperties.$markers = [] // GoogleMapのマーカー管理用
    App.config.globalProperties.$map = null // GoogleMapのMap管理
    App.config.globalProperties.$filters = {
      // globalFilterを定義
      // HH:MM:SS → HH:MM
      formatHHMM(time) {
        if (!time) {
          return ''
        }
        return time.slice(0, 5)
      },
      formatMDdd(date) {
        if (!date) {
          return ''
        }
        return date.format('M/D (dd)')
      },
      formatMDddHHmm(date) {
        if (!date) {
          return ''
        }
        return date.format('M/D (dd)HH:mm')
      },
      formatYYYYMMDDHHMM(date) {
        if (!date) {
          return ''
        }
        return date.format('YYYY/MM/DD(dd)HH:mm')
      },
      formatMMDDHHMM(date) {
        if (!date) {
          return ''
        }
        return date.format('MM/DD HH:mm')
      },
      convertBreakLine(str) {
        if (!str) {
          return ''
        }
        return str.replace(new RegExp('\r?\n', 'g'), '<br />')
      },
      formatNumber(number, fractionDigits = 0) {
        if (number === null || isNaN(number)) return 0
        return number.toFixed(fractionDigits).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      formatYYYYMMDD(value) {
        return value ? moment(value).format('YYYY/MM/DD') : ''
      },
      formatDateHHmm(value) {
        return value ? moment(value).format('HH:mm') : ''
      },
      formatDateHHmm24(value) {
        const ret = value ? moment(value).format('HH:mm') : ''
        return ret === '00:00' ? '24:00' : moment(value).format('HH:mm')
      },
    }
  },
}

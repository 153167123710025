<template>
  <div class="popup_caption">
    <div class="popup_body">
      <div class="popup_close"><img /></div>
      <div class="popup_ttl">{{ title }}</div>
      <div class="extend_popup" v-html="message"></div>
      <div class="btn_area login_btn">
        <div v-html="customOk" class="custom_btn" v-if="customOk"></div>
        <button type="button" class="btn_l btn_blue" v-else @click="modalResolve">
          {{ textOk }}
        </button>
        <button type="button" class="btn_l btn_neblue" v-if="!isDialog" @click="modalReject">
          {{ textCancel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      modalReject() {
        this.$store.dispatch('actionConfirmReject', false)
      },
      modalResolve() {
        this.$store.dispatch('actionConfirmResolve', true)
      },
    },
    computed: {
      title() {
        return this.$store.state.confirm.title
      },
      message() {
        return this.$store.state.confirm.message
      },
      isDialog() {
        return this.$store.state.confirm.isDialog
      },
      customOk() {
        return this.$store.state.confirm.customOk
      },
      textOk() {
        return this.$store.state.confirm.textOk
      },
      textCancel() {
        return this.$store.state.confirm.textCancel
      },
    },
  }
</script>
<style>
  .extend_popup {
    white-space: pre-line;
  }

  div.custom_btn {
    width: 100%;
    max-width: 120px;
  }

  div.custom_btn input[type='submit'] {
    margin-top: 0px;
  }
</style>

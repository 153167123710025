/* eslint-disable */

import { buildModuleTypes } from '@/helpers'

const moduleName = 'master'
const GETTER_TYPES = {
  CODE_CATEGORIES: 'CODE_CATEGORIES',
  CARSHARE_PARAM: 'CARSHARE_PARAM',
}
const MUTATION_TYPES = {
  SET_CODE_CATEGORIES: 'SET_CODE_CATEGORIES',
  SET_CARSHARE_PARAM: 'SET_CARSHARE_PARAM',
  CLEAR_VALUES: 'CLEAR_VALUES',
}

export const MASTER_GETTER_TYPES = buildModuleTypes({
  moduleName,
  types: GETTER_TYPES,
})

export const MASTER_MUTATION_TYPES = buildModuleTypes({
  moduleName,
  types: MUTATION_TYPES,
})

export const namespaced = true

export const state = {
  codeCategories: null,
  corpParam: null,
}

export const getters = {
  [GETTER_TYPES.CODE_CATEGORIES](state) {
    if (state.codeCategories === null) {
      return {}
    }
    return state.codeCategories
  },
  [GETTER_TYPES.CARSHARE_PARAM](state) {
    if (state.corpParam === null) {
      return {}
    }
    return state.corpParam
  },
}

export const mutations = {
  [MUTATION_TYPES.SET_CODE_CATEGORIES](state, values) {
    state.codeCategories = values
  },
  [MUTATION_TYPES.SET_CARSHARE_PARAM](state, values) {
    state.corpParam = values
  },

  [MUTATION_TYPES.CLEAR_VALUES](state) {
    state.codeCategories = null
    state.corpParam = null
  },
}

import { createRouter, createWebHistory } from 'vue-router'
import Config from '@/conf/Config'
import logger from '@/module/Logger'
import { isEmpty } from '@/helpers/validators'
import { isEmptyObject } from '@/helpers/validators'
import store from '@/store'
import { MUTATION_TYPES } from '@/store'
import Auth from '@/module/Auth'
import ErrorTracking from '@/module/ErrorTracking'
import { SESSION_GETTER_TYPES } from '@/store/modules/session'
import is from 'is_js'
import Statistics from '@/module/Statistics'

const routes = [
  {
    path: Config.URL.SESSION_TIMEOUT_PATH,
    name: 'SessionTimeOut',
    component: () => import(/* webpackChunkName: "about" */ '@/views/error/SessionTimeOut.vue'),

    meta: {
      requiresAuth: false,
      title: 'セッションタイムアウト',
    },
  },
  {
    path: Config.URL.BAD_REQUEST_PATH,
    name: 'BadRequest',
    component: () => import(/* webpackChunkName: "about" */ '@/views/error/BadRequest.vue'),
    meta: {
      requiresAuth: false,
      title: '不正な画面遷移',
    },
  },
  {
    path: Config.URL.SYSERR_PATH,
    name: 'SystemError',
    component: () => import(/* webpackChunkName: "about" */ '@/views/error/SystemError.vue'),
    meta: {
      requiresAuth: false,
      title: '予期せぬエラー',
    },
  },
  {
    path: Config.URL.MAINTENANCE_PATH,
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "about" */ '@/views/error/Maintenance.vue'),
    meta: {
      requiresAuth: false,
      title: 'メンテナンス',
    },
  },
  {
    path: Config.URL.ROOT,
    name: 'Home',
    // component: Home,
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
    meta: {
      requiresAuth: true,
      title: 'ステーション・車両検索',
      allowRoles: [Config.ROLE.ALL],
    },
  },
  {
    path: Config.URL.LOGIN_PATH,
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/login/Login.vue'),
    meta: {
      requiresAuth: false,
      title: 'ログイン',
    },
  },
  {
    path: Config.URL.RESET_PASSWORD,
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ResetPassword.vue'),
    meta: {
      requiresAuth: false,
      title: 'パスワード再設定',
    },
  },
  {
    path: Config.URL.CONFIRM_PASSWORD,
    name: 'ConfirmPassword',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ConfirmPassword.vue'),
    meta: {
      requiresAuth: false,
      title: 'リセットパスワードの確認',
    },
  },
  {
    path: Config.URL.QUESTIONNAIRE,
    name: 'Questionnaire',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Questionnaire.vue'),
    meta: {
      requiresAuth: true,
      title: '降車時アンケート',
      allowRoles: [Config.ROLE.ALL],
    },
  },
  {
    path: Config.URL.LOGOUT_PATH,
    name: 'Logout',
    component: () => import(/* webpackChunkName: "about" */ '@/views/login/Logout.vue'),
    meta: {
      requiresAuth: false,
      title: 'ログアウト',
    },
  },
  {
    path: Config.URL.MYPAGE,
    name: 'Mypage',
    component: () => import(/* webpackChunkName: 'about' */ '@/views/mypage/Mypage.vue'),
    meta: {
      requiresAuth: true,
      title: 'マイページ',
      allowRoles: [Config.ROLE.ALL],
    },
  },
  {
    path: Config.URL.COMPLETE,
    name: 'Complete',
    component: () => import(/* webpackChunkName: 'about' */ '@/views/Complete.vue'),
    meta: {
      requiresAuth: true,
      title: '予約完了',
      allowRoles: [Config.ROLE.ALL],
    },
  },
  {
    path: Config.URL.AUTH_3DS,
    name: 'Secured',
    component: () => import(/* webpackChunkName: 'about' */ '@/views/validate/Secured.vue'),
    meta: {
      requiresAuth: false, // TODO: タイムアウト電文を受け取る必要があるため認証なしで画面を開くか
      title: '3DS認証',
      allowRoles: [Config.ROLE.ALL],
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.meta.useCustomTitle) {
    // 事業者名をタイトルに載せたい場合はvuexから取得する必要がある
    document.title = `${to.meta.title}`
    if (isEmpty(to.meta.title)) {
      // ルーティングなし
      logger.info('nothing to route table')
      next(Config.URL.BAD_REQUEST_PATH)
      return
    }
  }
  const session = store.getters[SESSION_GETTER_TYPES.VALUES]
  if (to.meta.requiresAuth) {
    // 未ログイン時はログインページへリダイレクト
    if (isEmpty(session) || isEmptyObject(session)) {
      logger.info('Redirect to Login Form')
      sessionStorage.setItem(
        Config.REDIRECT_URL,
        JSON.stringify({
          path: to.path,
          query: to.query,
        })
      ) // redirect settings
      next(Config.URL.LOGIN_PATH)
      return
    }

    // 権限エラーのハンドリング(現在は特に指定なし)
    if (
      !to.meta.allowRoles.includes(Config.ROLE.ALL) &&
      !to.meta.allowRoles.some((role) => session.roles.includes(role))
    ) {
      logger.info('UnAuthorize Action')
      next(Config.URL.BAD_REQUEST_PATH)
      return
    }

    // セッション確認 (=token refresh)
    Auth.getSession()
      .then((res) => {
        if (is.not.empty(session) && res.isValid()) {
          logger.info('session available!')
          const refreshToken = res.getRefreshToken()
          store.commit(MUTATION_TYPES.SET_REFRESH_TOKEN, refreshToken.token)
        } else {
          next(Config.URL.SESSION_TIMEOUT_PATH)
        }
      })
      .catch(() => {
        next(Config.URL.SESSION_TIMEOUT_PATH)
      })

    // 降車時アンケート画面はマイページからのみ遷移可能
    if (to.path === Config.URL.QUESTIONNAIRE && from.path !== Config.URL.MYPAGE) {
      next(Config.URL.MYPAGE)
    }

    console.log(from.query.reservationId)
    // 完了画面は予約登録・変更画面・3DS認証後画面からのみ遷移可能
    if (to.path === Config.URL.COMPLETE) {
      if (
        from.path !== Config.URL.AUTH_3DS &&
        (from.path === Config.URL.ROOT &&
          (isEmpty(from.query.selectVehicleId) && isEmpty(from.query.reservationId)))
      ) {
        next(Config.URL.ROOT)
      }
    }
  }
  if (from.path !== to.path) {
    logger.info(`Screen transition. from=(${from.path}) to=(${to.path})`, {
      id: session.username,
    })
    const statistics = new Statistics()
    statistics.setAccessLog(to.path)
  }
  next()
})

// router.afterEach((to, from) => {
// });

router.onError((error) => {
  ErrorTracking.captureException(error)
  ErrorTracking.showReportDialog()
})

export default router

<template>
  <header id="header">
    <div class="widthset">
      <div class="cont">
        <a
          href="#"
          v-if="corpParamContents.HEADER"
          @click.prevent.stop="goPath('/')"
          :class="{
            'no-events': isLoading,
          }"
        >
          <img :src="corpParamContents.HEADER.images.headerLogo" />
        </a>
        <div class="header">
          <a
            href="#"
            class="station-src"
            :class="{
              activlink: currentPath === '/',
              'no-events': isLoading,
            }"
            @click.prevent.stop="goPath('/')"
            >ステーション・車両検索</a
          >
          <a
            href="#"
            class="action"
            :class="{
              activlink: currentPath === '/mypage',
              'no-events': isLoading,
            }"
            @click.prevent.stop="goPath('/mypage')"
            >利用開始/利用終了</a
          >
          <a
            href="#"
            id="logout"
            class="loginout"
            :class="{
              'no-events': isLoading,
            }"
            @click.prevent.stop="logout"
            >ログアウト</a
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import Config from '@/conf/Config'
  import { MUTATION_TYPES } from '@/store'
  import { LOGIN_MESSAGE } from '@/conf/Message'

  export default {
    name: 'Header',
    props: {},
    methods: {
      goHome() {
        this.$router.push('/')
      },
      goPath(path) {
        if (path == this.currentPath) {
          this.$router.go({ path: this.currentPath, force: true })
          // 理想は親画面にresfreshメソッドを用意しておき、そのt処理を呼び出す
        } else {
          this.$router.push(path)
        }
      },
      toggle() {
        this.$store.commit(MUTATION_TYPES.SET_COLLAPSE, !this.isCollapse)
      },
      async logout() {
        await this.$_confirm.confirmOpen(LOGIN_MESSAGE.LOGOUT_CONF).then(() => {
          this.$router.push(Config.URL.LOGOUT_PATH)
        })
      },
    },
    computed: {
      isLoading() {
        return (
          this.processing ||
          this.my_station_processing ||
          this.map_processing ||
          this.ev_processing ||
          this.vehicle_processing
        )
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a.no-events {
    pointer-events: none;
  }
</style>

import { mapGetters } from 'vuex'
import SimpleLayouts from '@/components/layouts/SimpleLayouts.vue'
import Config from '@/conf/Config'
import { GETTER_TYPES } from '@/store'
import { SESSION_GETTER_TYPES } from '@/store/modules/session'
import { MASTER_GETTER_TYPES } from '@/store/modules/master'
import { API_ERROR_MESSAGE, API_ERROR_MESSAGE_RESPONSE } from '@/conf/Message'
import { API_ERROR_CODE } from '@/constants/constant.api'
import { isEmptyObject } from '@/helpers/validators'

import Auth from '@/module/Auth'
import _ from 'lodash'

/*
 * グローバル共通処理
 */
export default {
  components: {
    SimpleLayouts,
  },

  beforeRouteLeave(to, from, next) {
    next()
  },

  created() {},

  methods: {
    getTargetList: (targetList, roles) =>
      targetList.filter((row) =>
        row.roles.some((role) => role === Config.ROLE.ALL || roles.includes(role))
      ),
    go(url) {
      this.$router.push(url)
    },
    async validationErrorHook(err) {
      try {
        err = await this.handlerApiError(err)
        return err
      } catch (e) {
        throw new Error(e)
      }
    },

    // エラーに応じて処理を行う
    async handlerApiError(error) {
      const response = error.response ? error.response : {}

      let json = null

      try {
        json = await response.json()
      } catch (e) {
        console.log(e)
      }

      console.log(json, response)

      if (response.status === API_ERROR_CODE.UNAUTHORIZED) {
        // リフレッシュトークンの有効期限切れの場合は再取得は実施しない。
        const message = json != null ? json.message : API_ERROR_MESSAGE.UNAUTHORIZED_ERROR
        this.$_confirm.dialogOpen(message)
        error.response.message = message
        this.go('login')
      } else if (response.name === API_ERROR_CODE.TIMEOUT_ERROR) {
        // タイムアウトエラー
        const message = API_ERROR_MESSAGE.TIMEOUT_ERROR
        this.$_confirm.dialogOpen(message)
        error.response.message = message
        return error
      } else if (response.status === API_ERROR_CODE.CONFLICT) {
        // 予期せぬエラー
        const message = API_ERROR_MESSAGE.MUTUAL_EXCLUSION
        this.$_confirm.dialogOpen(message)
        error.response.message = message
        return error
      } else if (
        response.status === API_ERROR_CODE.SERVICE_UNAVAILABLE &&
        json != null &&
        json.code === 'maintenance'
      ) {
        // メンテナンスモード
        this.go('maintenance')
        return error
      } else if (
        response.name === API_ERROR_CODE.NETWORK_DISCONNECT_NAME &&
        response.statusText === API_ERROR_CODE.NETWORK_DISCONNECT_TEXT
      ) {
        // NETWORK_ERROR
        const message = API_ERROR_MESSAGE.NETWORK_ERROR
        this.$_confirm.dialogOpen(message)
        error.response.message = message
        return error
      } else if (json != null && json.errorCode === API_ERROR_CODE.INVALID_PARAMETER) {
        // 業務エラー(APIのエラーメッセージをそのまま表示する)
        const message = json.message
        this.$_confirm.dialogOpen(message)
        error.response.message = message
        return error
      } else if (_.isEmpty(response) && error == 'TypeError: Failed to fetch') {
        // 割り込み操作による中断
        return null
      } else {
        // システムエラー
        const message = json != null ? json.message : API_ERROR_MESSAGE_RESPONSE.SYSTEM_ERROR
        this.$_confirm.dialogOpen(message)
        if (!_.isEmpty(response)) {
          error.response.message = message
        }
        return error
      }
    },
    async getErrorMessage(e) {
      const response = e.response ? e.response : {}

      let json = null

      try {
        json = await response.json()
      } catch (e) {
        console.log('Response is empty ', e)
      }

      return json ? json.message : API_ERROR_MESSAGE_RESPONSE.SYSTEM_ERROR
    },
    isEmptyObject(obj) {
      return isEmptyObject(obj)
    },
  },

  computed: {
    ...mapGetters({
      session: SESSION_GETTER_TYPES.VALUES,
      processing: GETTER_TYPES.GET_PROCESSING,
      map_processing: GETTER_TYPES.GET_MAP_PROCESSING,
      my_station_processing: GETTER_TYPES.GET_MY_STATION_PROCESSING,
      ev_processing: GETTER_TYPES.GET_EV_PROCESSING,
      vehicle_processing: GETTER_TYPES.GET_VEHICLE_PROCESSING,
      dirty: GETTER_TYPES.GET_DIRTY,
      isCollapse: GETTER_TYPES.GET_COLLAPSE,
      page: GETTER_TYPES.GET_PAGE,
      codeCategories: MASTER_GETTER_TYPES.CODE_CATEGORIES,
      sex: MASTER_GETTER_TYPES.SEX,
      corpParam: MASTER_GETTER_TYPES.CARSHARE_PARAM,
    }),
    // カーシェア事業者パラメータマスタコンテンツ
    corpParamContents() {
      return this.corpParam.reservationWebContents
        ? JSON.parse(this.corpParam.reservationWebContents)
        : {}
    },
    isLogin() {
      return this.$is.not.empty(this.session) && Auth.isLogin()
    },
    cleanOrMaintenance() {
      return this.session.cleaningCorp || this.session.maintenanceCorp
    },
    isCorporateMember() {
      return this.session.corpId != null
    },
    appPrefix() {
      return Config.MY_APP_PREFIX
    },
    hasState() {
      return this.$store !== undefined
    }, // pluginはステートを持たない
    roles() {
      return this.session && this.session.roles ? this.session.roles : []
    },
    isProduction() {
      return this.$store.state.mode === 'production'
    },
    targetSorce() {
      return this.isLogin ? 'メニュー' : 'ログインページ'
    },
    currentPath() {
      // return this.$router.history.current.path; // vue2
      return this.$route.path
    },
    currentQuery() {
      // return this.$router.history.current.query; // vue2
      return this.$route.query
    },
    currentName() {
      // return this.$router.history.current.name; // vue2
      return this.$route.name
    },
  },
}

import { defineRule } from 'vee-validate'
import { email, max, required, confirmed } from '@vee-validate/rules'
import * as validators from '@/helpers/validators'
import { VALIDATOR_PATTERN } from '@/constants/constant.validation'

defineRule('required', required)
defineRule('email', email)
defineRule('max', max)
defineRule('confirmed', confirmed)

// Custom rules

defineRule('katakana', (value) => {
  return validators.isKataKana(value)
})
// Custom Rules Check min max length value input ( get message from constant.message.js)
defineRule('betweenMinMaxLength', (value, [field, min, max]) => {
  if (!value || !value.length) {
    return true
  }
  if (value.length < min || value.length > max) {
    return `${field}は${min}文字以上${max}文字以内で入力してください。` //E0035
  }
  return true
})
defineRule('passwordPattern', (value) => {
  const patternPassword = VALIDATOR_PATTERN.passwordPattern
  return patternPassword.test(value)
})

// 相関チェック 関連項目が[99:その他]を選択している場合、必須入力とする
defineRule('relationRequired', (value, [field, relationField]) => {
  if (relationField == '99' && (!value || !value.length)) {
    return `${field}は必須項目です。`
  }
  return true
})

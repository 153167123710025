import _ from 'lodash'
import { convertKeys, convertValueToString } from '@/helpers/object'

function extractCodebyCodeKey(codeList, codeKey) {
  try {
    return _(codeList)
      .filter((code) => {
        return code.codeCategoryKey === codeKey
      })
      .flatMap((code) => {
        return code.codeContainer
      })
      .map((codeContainer) => {
        return convertValueToString(
          convertKeys(
            codeContainer,
            ['codeKey', 'key'],
            ['codeAlias', 'value'],
            ['displayOrder', 'displayOrder']
          )
        )
      })
      .value()
  } catch (e) {
    return []
  }
}

export { extractCodebyCodeKey as default }

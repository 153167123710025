export const MESSAGE = {
  // I0001: '登録完了しました。',
  // I0002: '変更完了しました。',
  // I0003: 'パスワードを変更しました。',
  // I0004: '予約が完了しました。',
  // I0005: '予約内容を変更しました。',
  // I0006: '予約をキャンセルしました。',
  // I0007: '退会が完了しました。',
  // I0008: '{0}まで延長しました。',
  I0009: '利用を開始しました。',
  // I0010: '利用を終了しました。',
  // I0011: '忘れ物解錠しました。',
  // I0012: '施錠が完了しました。',
  // I0013: '削除完了しました。',
  // W0001: '開始すると鍵が開きます。<br>' +
  //     '利用を開始してよろしいですか？',
  // W0002: 'この予約を取り消しますか？',
  // W0003: '"この予約を取り消しますか？<br>' +
  //     '開始時間を過ぎてからの取り消しは、料金が発生します。<br>' +
  //     '取り消しますか？"<br>',
  // W0004: '利用時間を{0}延長します。<br>' +
  //     'よろしいですか？',
  // W0005: '利用終了から1時間以内に一度だけ解錠できます。<br>' +
  //     '解錠後、10分以内に施錠されない場合は帰着遅延としてペナルティ料金が発生しますので十分ご注意ください。<br>' +
  //     '解錠しますか？',
  // W0006: '施錠します。<br>' +
  //     'よろしいですか？',
  // W0007: '編集中の内容は失われます。<br>' +
  //     'よろしいですか?',
  // W0008: '審査ステータスを変更します。<br>' +
  //     'よろしいですか?',
  // W0009: '審査ステータスを「審査通過」「審査落ち」に変更した場合、<br>' +
  //     '会員から再審査依頼が行われるまで、審査ステータスの変更が行えなくなります。<br>' +
  //     '審査ステータスを変更しますか？',
  // W0010: '入力された郵便番号に合致する住所が存在しませんでした。',
  // W0011: '会員情報を変更します。<br>' +
  //     'よろしいですか？',
  // W0012: '現在表示している会員を個人会員に変更します。<br>' +
  //     'よろしいですか？',
  // W0013: '入力された内容でユーザーを登録します。<br>' +
  //     'よろしいですか？',
  // W0014: 'ユーザー情報を変更します。<br>' +
  //     'よろしいですか？',
  // W0015: '現在表示しているユーザーを削除します。<br>' +
  //     'よろしいですか？',
  E0001: '{field}は必須項目です。',
  E0002: '{field}は0:{max}文字以内で入力してください。',
  // E0003: '{0}は半角英数字で入力してください。',
  E0004: '{field}は半角数字で入力してください。',
  // E0005: '{0}は郵便番号形式(9999999)で入力してください。',
  // E0006: '{0}は電話番号形式(99999-99999-99999)で入力してください。',
  // E0007: '{0}は日付形式(YYYY/mm/dd)で入力してください。',
  // E0008: '{0}は時間形式(HH:MM)で入力してください。',
  // E0009: '{0}は{1}以降の日付を指定してください。',
  // E0010: 'チェック項目が存在しません。',
  // E0011: 'ファイルのサイズが{0}を超えています。',
  // E0012: '{0}は半角で入力してください。',
  E0013: '{field}はカタカナで入力してください。',
  // E0014: '{0}を削除することはできません。',
  // E0015: '既に更新されています。読み込み直してください。',
  // E0016: '該当の{0}は存在しません。',
  // E0017: '{0}は既に登録されています。',
  // E0018: '{0}は不正なデータです。',
  // E0019: '{0}はファイル形式が不正です。',
  // E0020: '{0}をチェックしてください。',
  // E0021: '{0}は数字４桁で入力してください。',
  // E0022: '{0}は全角で入力してください。',
  // E0023: '{0}は{1}文字で入力してください。',
  // E0024: '{0}または{1}を入力してください。',
  // E0025: '{0}を入力してください。',
  // E0026: '未払い料金が存在するため退会できません。しばらく経ってから再度お試しください。',
  // E0027: '車両を利用中のため退会できません。利用を終了してから退会してください。',
  // E0028: '{0}できません。',
  // E0029: 'PUSH通知の設定がオフになっています。<br>' +
  //     '端末の設定画面から通知設定をオンにしてから利用してください。',
  // E0030: '位置情報の取得が許可されていません。<br>' +
  //     '端末の設定画面から位置情報の取得を許可してから利用してください。',
  // E0031: 'この付近にステーションが存在しません。',
  // E0032: '検索範囲が広すぎるため検索できません。',
  // E0033: '{0}は{1}文字以上{2}文字以内で入力してください。',
  E0034: '{1}と{2}が一致しません。',
  E0035: '{0}は{1}文字以上{2}文字以内で入力してください。',
  // E0036: '{0}はハイフンを入力しないでください。',
  // E0037: '{0}は数字を入力してください。',
  // E0038: '{0}は{1}以前の日付を指定してください。',
  // E0039: '予約終了時間が過ぎています。',
  // E0040: '忘れ物解錠可能な時間が過ぎています。',
  // E0041: '入力されたパスワードは現在のパスワードと一致しています。',
  // E0042: '古いパスワードが誤っています。',
  // E0043: 'パスワードと確認用パスワードが一致しません。',
  // E0044: '入力された認証コードは無効です。',
  // E0045: '入会登録をするには規約に同意していただく必要があります。',
  // E0046: '本予約は、帰着遅延が発生しているため、延長できません。',
  E0047: '{0}はアルファベット（大文字小文字混在）と数字を組み合わせてください。',
  // E0048: '運転免許証情報の変更に失敗しました。お手数ですが、ご確認の上、再度お試しください。',
  // E0049: 'エネマネスポットが登録されていないため、EVステーションを公開できません。',
  // E0050: 'エネマネスポットが登録されているため、EVステーションは削除できません。',
  // E0111: '入力されたプロモーションコードは使用できません。',
  // E0112: '入力されたプロモーションコードは既に使用済みです。',
  // E0113: '入力されたプロモーションコードは初回予約時のみ有効のため使用できません。',
  // E0114: '法人枠内の予約の場合、プロモーションコードは使用できません。',
  // E0142: '追加運転者様のメールアドレスが重複しています。入力内容をご確認ください。',
  // E0143: '予約者様自身が追加運転者に指定されています。入力内容をご確認ください。',
  // E0144: '(.*?) 様の会員情報が見つかりません。入力内容をご確認ください。',
  // E0145: '(.*?) 様の会員情報の確認が完了していないため、追加運転者に指定できません。',
  // E0146: '予約開始時間が (.*?) 様のカーシェア利用開始日時前のため、追加運転者に指定できません。',
  // E0147: '予約終了時間が (.*?) 様の免許証有効期限後のため、追加運転者に指定できません。',
  // E0148: '追加運転者として登録されている予約が存在するため退会できません。',
  // MAX_LENGTH_NUMBER: '{0}は数字{1}桁で入力してください。',
  // MAX_LENGTH_TEXT: '{0}は{1}文字以下で入力してください。',
  // START_TIME_BEFORE_CURRENT: '現在時刻より前の時間は選択できません。',
  // END_TIME_AFTER_START_TIME: '予約終了日時は予約開始日時より未来の日時を指定してください。',
  // TIME_RESERVATION_WITHIN_72HOUR: '予約時間は72時間以内におさめてください。',
  // START_TIME_MAX_DATETIME: '予約開始時間は2週間後の23:30まで設定できます。',
  VERIFY_CODE_MISMATCH: '認証コードが違います。',
  EMAIL_NOT_REGISTERED: '登録されていないメールアドレスです。',
  // WRONG_PASSWORD: '古いパスワードが違います。',
  LOGIN_FAIL: 'メールアドレスまたはパスワードが違います。',
  // CVC_CODE_SIZE: 'セキュリティコードは3桁か4桁で入力してください。',
  // CARD_HOLDER_NAME_UPPERCASE: 'カード名義人は大文字アルファベットまたは半角スペースのみで入力してください。',
  WRONG_INPUT_FORMAT: '{field}形式で入力してください。',
  // LENGTH_BETWEEN: '{0}は数字{1}桁、もしくは{2}桁で入力してください。',
  // EMERGENCY_INFO_DUPLICATED: 'ご自身の連絡先とは別の連絡先を入力してください。',
  // LICENSE_DUPLICATED: '緊急連絡先の氏名と一致するため、入力内容を変更するか緊急連絡先の氏名を変更してください',
  // INVALID_PATTERN_EMAIL: 'メールアドレス形式で入力してください。',
  // EXCEEDING_MAX_LENGTH: 'メールアドレスは128文字以内で入力してください。'
}

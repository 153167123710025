import is from 'is_js'
import store, { GETTER_TYPES } from '@/store'
import querystring from 'querystring' // eslint-disable-line
import { SESSION_GETTER_TYPES } from '@/store/modules/session'

class Api {
  /** *******************
   * Get Settings
   * ****************** */
  static makeGetUrl = (base, where) =>
    base + (is.empty(where) ? '' : `?${querystring.stringify(where)}`)

  static getHeader = () => ({
    'X-App-Type': 'front', // app type
    'X-User-Id': store.getters[SESSION_GETTER_TYPES.VALUES].memberId, // member Id
    /* eslint-disable */
    'X-Carshare_Corp-Id': CARSHARE_CORP_ID,
    'X-Cognito-Id': store.getters[SESSION_GETTER_TYPES.VALUES].sub, // cognito id
    'X-Refresh-Token': store.getters[GETTER_TYPES.GET_REFRESH_TOKEN], // refresh token
    'Content-Type': 'application/json',
  })

  // get filter
  /* filter implements */
  static searchEnd = (response) => response

  static checkStatus = async (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response
    }
    // リダイレクト 3Dセキュア時を想定
    if (response.status >= 300 && response.status < 400) {
      return response
    }

    // API呼び出し側でcatchして validationErrorHook にエラーを渡す
    const error = new Error(response.statusText)
    error.response = response
    console.log('api', error)
    throw error
  }

  static toJson = (response) => response.json()

  static toText = (response) => response.text()

  static fetchGet = (base, where = {}) =>
    window
      .fetch(this.makeGetUrl(base, where), {
        method: 'GET',
        headers: this.getHeader(),
      })
      .then(this.searchEnd)
      .then(this.checkStatus)

  /** *******************
   * Post/Put Settings
   * ****************** */
  static submitButton = () => document.querySelectorAll('.button')

  static updateHeader = () => ({
    'X-Requested-With': 'csrf', // csrf header
    'X-App-Type': 'front', // app type
    'X-User-Id': store.getters[SESSION_GETTER_TYPES.VALUES].memberId, // member id
    'X-Carshare_Corp-Id': CARSHARE_CORP_ID, // carshare corp id
    'X-Cognito-Id': store.getters[SESSION_GETTER_TYPES.VALUES].sub, // cognito id
    'X-Refresh-Token': store.getters[GETTER_TYPES.GET_REFRESH_TOKEN], // refresh token
    'Content-Type': 'application/json',
  })

  static updateFileHeader = () => ({
    'X-Requested-With': 'csrf', // csrf header
    'X-App-Type': 'front', // app type
    'X-User-Id': store.getters[SESSION_GETTER_TYPES.VALUES].memberId, // member id
    'X-Carshare_Corp-Id': CARSHARE_CORP_ID, // carshare corp id
    'X-Cognito-Id': store.getters[SESSION_GETTER_TYPES.VALUES].sub, // cognito id
    'X-Refresh-Token': store.getters[GETTER_TYPES.GET_REFRESH_TOKEN], // refresh token
  })

  /* filter implements */
  static updateStartFilter = () => {}

  /* filter implements */
  static updateEndFilter = () => {}

  static updatehEnd = (response) => {
    this.updateEndFilter()
    return response
  }

  static postBase = (url, data) => {
    document.cookie = 'uid=; max-age=0' // 2秒ロック回避のためuid削除
    this.updateStartFilter()
    return window.fetch(url, {
      method: 'POST',
      headers: this.updateHeader(),
      body: JSON.stringify(data),
    })
  }

  static postFile = (url, data) => {
    this.updateStartFilter()
    return window.fetch(url, {
      method: 'POST',
      headers: this.updateFileHeader(),
      body: data,
    })
  }

  static putBase = (url, data) => {
    document.cookie = 'uid=; max-age=0' // 2秒ロック回避のためuid削除
    this.updateStartFilter()
    return window.fetch(url, {
      method: 'PUT',
      headers: this.updateHeader(),
      body: JSON.stringify(data),
    })
  }

  static fetchPost = (url, data) =>
    this.postBase(url, data).then(this.updatehEnd).then(this.checkStatus)

  static fetchPostFile = (url, data) =>
    this.postFile(url, data).then(this.updatehEnd).then(this.checkStatus)

  static fetchPut = (url, data) =>
    this.putBase(url, data).then(this.updatehEnd).then(this.checkStatus)

  /** *******************
   * Delete Settings
   * ****************** */
  static fetchDelete = (url) =>
    window
      .fetch(url, {
        method: 'DELETE',
        headers: this.updateHeader(),
      })
      .then(this.checkStatus)
}

const API_CONFIG = {}
API_CONFIG.HOST = window.location.origin
API_CONFIG.BASE_URL = `${API_CONFIG.HOST}/admin`
API_CONFIG.BASE_URL_COMMON = `${API_CONFIG.BASE_URL}/api/common`
API_CONFIG.ENDPOINT_LOG = `${API_CONFIG.BASE_URL}/api/log`
API_CONFIG.ENDPOINT_CODE = `${API_CONFIG.BASE_URL}/api/code`
API_CONFIG.ENDPOINT_TRANSLATE = `${API_CONFIG.BASE_URL}/api/translate`
API_CONFIG.ENDPOINT_CORPORATION = `${API_CONFIG.BASE_URL}/api/corporation`
API_CONFIG.ENDPOINT_CORPORATION_FRAME = `${API_CONFIG.BASE_URL}/api/corporation_frame`
API_CONFIG.ENDPOINT_CORP_FRAME_CYCLE = `${API_CONFIG.BASE_URL}/api/corp_frame_cycle`
API_CONFIG.ENDPOINT_EV = `${API_CONFIG.BASE_URL}/api/evstation`
API_CONFIG.ENDPOINT_VEHICLE = `${API_CONFIG.BASE_URL}/api/vehicle`
API_CONFIG.ENDPOINT_VEHICLE_RESERVATION = `${API_CONFIG.BASE_URL}/api/vehicle_reservation`
API_CONFIG.ENDPOINT_SESSION = `${API_CONFIG.BASE_URL}/api/session/front`
API_CONFIG.ENDPOINT_MEMBER = `${API_CONFIG.BASE_URL}/api/members`
API_CONFIG.ENDPOINT_MEMBER_UNSUBSCRIBE = `${API_CONFIG.BASE_URL}/api/member_unsubscribe`
API_CONFIG.ENDPOINT_CHARGE_PLAN = `${API_CONFIG.BASE_URL}/api/charge_plan`
API_CONFIG.ENDPOINT_DOWNLOAD_IMAGE = `${API_CONFIG.BASE_URL}/api/private_download_image`
API_CONFIG.ENDPOINT_RESERVATION = `${API_CONFIG.BASE_URL}/api/reservation`
API_CONFIG.ENDPOINT_RESERVATION_START = `${API_CONFIG.BASE_URL}/api/reservation_start`
API_CONFIG.ENDPOINT_RESERVATION_STOP = `${API_CONFIG.BASE_URL}/api/reservation_stop`
API_CONFIG.ENDPOINT_RESERVATION_CANCEL = `${API_CONFIG.BASE_URL}/api/reservation_cancel`
API_CONFIG.ENDPOINT_RESERVATION_EXTENTION_TIME = `${API_CONFIG.BASE_URL}/api/reservation_extention_time`
API_CONFIG.ENDPOINT_RESERVATION_GET_007_EXTEND_TIME = `${API_CONFIG.BASE_URL}/api/reservation/extendable_time`
API_CONFIG.ENDPOINT_QUESTIONNAIRE = `${API_CONFIG.BASE_URL}/api/usage_questionnaire`
API_CONFIG.PUT_012_USAGE_QUESTIONNAIRE = `${API_CONFIG.BASE_URL}/api/usage_questionnaire`
API_CONFIG.ENDPOINT_REENERGY = `${API_CONFIG.BASE_URL}/api/renewable_energy_ratio`
API_CONFIG.ENDPOINT_CHARGE = `${API_CONFIG.BASE_URL}/api/charge`
API_CONFIG.ENDPOINT_CHARGE_UNIT = `${API_CONFIG.BASE_URL}/api/charge/unit_list`
API_CONFIG.ENDPOINT_INSURANCE = `${API_CONFIG.BASE_URL}/api/insurance`
API_CONFIG.ENDPOINT_PROMOTION = `${API_CONFIG.BASE_URL}/api/promotion`
API_CONFIG.ENDPOINT_SEARCH_MAP = `${API_CONFIG.BASE_URL}/api/map_vehicle_list`
API_CONFIG.ENDPOINT_USAGE_HISTORY = `${API_CONFIG.BASE_URL}/api/usage_history`
API_CONFIG.ENDPOINT_GET_011_MY_PAGE_INFO = `${API_CONFIG.BASE_URL}/api/my_page_infos`
API_CONFIG.ENDPOINT_FAVORITE = `${API_CONFIG.BASE_URL}/api/favorite`

/** *******************
 * API Settings
 * ****************** */
export const logApi = {
  access: (path) => Api.fetchGet(`${API_CONFIG.ENDPOINT_LOG}/access`, path),
  error: (data) => Api.fetchPost(`${API_CONFIG.ENDPOINT_LOG}/error`, data),
}

export const masterApi = {
  getCodeCategory: () => Api.fetchGet(`${API_CONFIG.BASE_URL}/api/codeCategory`).then(Api.toJson),
}

export const codeApi = {
  findAll: (where) => Api.fetchGet(API_CONFIG.ENDPOINT_CODE, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_CODE}/${id}`).then(Api.toJson),
}

export const translateApi = {
  translateEnglish: (word) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_TRANSLATE}/en?text=${word}`).then(Api.toText),
}
export const evApi = {
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_EV}/${id}`).then(Api.toJson),
  findCarshareUseCorpListByType: (id, type) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_EV}/corp_list/${id}/${type}`).then(Api.toJson),
}

export const vehicleApi = {
  findById: (id, where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/reserve/${id}`, where).then(Api.toJson),
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/list`, where).then(Api.toJson),
  findCarshareUseCorpListByType: (id, type) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/corp_list/${id}/${type}`).then(Api.toJson),
  getVehicleReserve: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE_RESERVATION}/detail`, where).then(Api.toJson),
}

export const sessionApi = {
  start: (sub) => Api.fetchGet(`${API_CONFIG.ENDPOINT_SESSION}/${sub}`).then(Api.toJson),
}

export const memberApi = {
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_MEMBER}/list`, where).then(Api.toJson),
  findById: (id, carshareCorpId) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_MEMBER}/${id}`, carshareCorpId).then(Api.toJson),
  getMonthlyCharge: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_MEMBER}/monthly_charge`, where).then(Api.toJson),
  cancelCorpTying: (id, data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_MEMBER}/corp_tying/${id}`, data).then(Api.toJson),
  checkChargedReservationExist: (id, carshareCorpId) =>
    Api.fetchGet(
      `${API_CONFIG.ENDPOINT_MEMBER}/exist_charged_reservation/${id}`,
      carshareCorpId
    ).then(Api.toJson),
}

export const chargePlanApi = {
  findAll: (where) => Api.fetchGet(API_CONFIG.ENDPOINT_CHARGE_PLAN, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_CHARGE_PLAN}/${id}`).then(Api.toJson),
  findChargeUnit: (where) => Api.fetchGet(API_CONFIG.ENDPOINT_CHARGE_UNIT, where).then(Api.toJson),
}

export const commonApi = {
  getCorpParam: () =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/carshare_corp_param`, {
      carshareCorpId: CARSHARE_CORP_ID,
    }).then(Api.toJson),
  findVehicleTypeList: (where) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/vehicle_type_list`, where).then(Api.toJson),
  findCarshareUseCorpListByType: (id, type) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/corp_list/${id}/${type}`).then(Api.toJson),
  findVehicleTypeList: (id) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/vehicle_type_list`, { carshareCorpId: id }).then(
      Api.toJson
    ),
  getListService: (data) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/service_list_web`, data).then(Api.toJson),
  getSystemMaintenance: () =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/system_maintenance`).then(Api.toJson),
  findVehicleTypeGroupList: (id) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/vehicle_type_group_list`, {
      carshareCorpId: id,
    }).then(Api.toJson),
}

export const reservationApi = {
  findList: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_RESERVATION}`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_RESERVATION}/${id}`).then(Api.toJson),
  findAvailability: (where) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_RESERVATION}/availability`, where).then(Api.toJson),
  updateReservation: (id, where) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION}/${id}`, where).then(Api.toJson),
  createReservation: (where) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_RESERVATION}`, where).then(Api.toJson),
  createReservationSecured: (where) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_RESERVATION}/securedReserve`, where).then(Api.toJson),
  updateFixedUsingDatetime: (id, where)  =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION}/update_fixed_using_datetime/${id}`, where).then(Api.toJson),
  updateAlcoholCheckBefore: (id, path, data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION}/alcohol_check_results/${path}/${id}`, data).then(Api.toJson),
}

export const questionnaireApi = {
  findById: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_QUESTIONNAIRE}?reservationId=${id}`).then(Api.toJson),
  putQuestionnaire: (reservationId, data) =>
    Api.fetchPut(`${API_CONFIG.PUT_012_USAGE_QUESTIONNAIRE}/${reservationId}`, data).then(
      Api.toJson
    ),
}

export const reEnergyApi = {
  findOne: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_REENERGY}/${id}`).then(Api.toJson),
}

export const chargeApi = {
  findUsageCharge: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_CHARGE}/usage_charge`, where).then(Api.toJson),
  findChargeUnit: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_CHARGE}/unit`, where).then(Api.toJson),
}

export const insuranceApi = {
  findByReservationStartDate: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_INSURANCE}`, where).then(Api.toJson),
}

export const reservationStartApi = {
  usingStart: (id) => Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION_START}/${id}`),
}

export const reservationStopApi = {
  usingStop: (id, version) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION_STOP}/${id}`, version),
}

export const reservationCancelApi = {
  reservationCancel: (id) => Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION_CANCEL}/${id}`),
}

export const reservationExtentionTimeApi = {
  extend: (id, where) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION_EXTENTION_TIME}/${id}`, where),

  findMaxExtendableTime: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_RESERVATION_GET_007_EXTEND_TIME}/${id}`).then(Api.toJson),
}

export const promotionApi = {
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_PROMOTION}/list`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_PROMOTION}/${id}`).then(Api.toJson),
}

export const searchMapApi = {
  searchMap: (latitude, longitude, distance, reservationStartDatetime, reservationEndDatetime, isCluster) =>
    // const distance = Utils.calculateDistanceByZoomAndCenter(zoom, latitude, widthMap);
    Api.fetchGet(
      `${API_CONFIG.ENDPOINT_SEARCH_MAP}?location=${latitude},${longitude}&distance=${distance}&reservationStartDatetime=${reservationStartDatetime}&reservationEndDatetime=${reservationEndDatetime}&cluster=${isCluster}`
    ).then(Api.toJson),
}

export const usageHistoryApi = {
  findList: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_USAGE_HISTORY}/list`, where).then(Api.toJson),
  findOne: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_USAGE_HISTORY}/${id}`).then(Api.toJson),
}

export const myPageInfoApi = {
  findMyPageInfo: () =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_GET_011_MY_PAGE_INFO}`).then(Api.toJson),
}

export const favoriteApi = {
  findMyStation: () =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_FAVORITE}/stations/list`).then(Api.toJson),
  updateMyStation: (data) => Api.fetchPut(`${API_CONFIG.ENDPOINT_FAVORITE}/stations`, data),
  updateMyCar: (data) => Api.fetchPut(`${API_CONFIG.ENDPOINT_FAVORITE}/vehicles`, data),
}

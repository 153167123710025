<template>
  <div>
    <app-header></app-header>
    <h2>
      <slot name="title"></slot>
    </h2>

    <slot name="content"></slot>
  </div>
</template>

<script>
  export default {
    props: {},
    data() {
      return {}
    },
    methods: {},
  }
</script>

<style scoped></style>

<template>
  <div v-if="corpParamContents.FOOTER">
    <div v-if="isLoginFooter">
      <footer>
        <p v-html="corpParamContents.FOOTER.article.copyRight"></p>
      </footer>
    </div>
    <div class="footer_bottom" v-else>
      <div class="footer_menu">
        <div class="footer_logo">
          <img :src="corpParamContents.FOOTER.images.footerLogo" />
        </div>
        <ul>
          <li><a :href="corpParam.userGuideUrl" target="blank">使い方・ユーザーガイド</a></li>
          <li><a :href="corpParam.faqUrl" target="blank">よくある質問・お問い合わせ</a></li>
          <li><a :href="corpParam.agreementUrl" target="blank">利用規約・貸渡約款</a></li>
          <li><a :href="corpParam.privacyPolicyUrl" target="blank">プライバシーポリシー</a></li>
        </ul>
      </div>
      <div class="footer_copy">
        <p v-html="corpParamContents.FOOTER.article.copyRight"></p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isLoginFooter: {
        type: [String, Boolean],
        default: () => false,
      },
    },
  }
</script>

<style scoped></style>

/**
 * 定数定義
 */
export default {
  MY_APP_PREFIX: 'reserve-web-app',

  DEFAULT_ROWS: '10',
  LOG_RETRY_MAX: 5, // アクセスログ保存のリトライ上限

  REDIRECT_URL: 'redirectUrl',

  COOKIE_ID: 'sid=',
  FUNCTION_ID: 'fid=',

  SEPARATOR: ', ',

  ERROR_PATH: ['systemError', 'sessionTimeOut', 'badRequest'], // エラーのパスリスト

  ISO_DATE_FORMAT: 'yyyy-MM-dd',
  ISO_DATETIME_FORMAT: 'yyyy-MM-ddTHH:mm:ss',
  ISO_TIME_FORMAT: 'HH:mm:ss',

  URL: {
    LOGIN_PATH: '/login',
    LOGOUT_PATH: '/logout',
    RESET_PASSWORD: '/resetPassword',
    CONFIRM_PASSWORD: '/confirmPassword',
    SYSERR_PATH: '/systemError',
    MAINTENANCE_PATH: '/maintenance',
    SESSION_TIMEOUT_PATH: '/sessionTimeOut',
    BAD_REQUEST_PATH: '/badRequest',
    ROOT: '/',
    MYPAGE: '/mypage',
    COMPLETE: '/complete',
    AUTH_3DS: '/validateSecure',
    USE_HISTORY: '/useHistory',
    QUESTIONNAIRE: '/questionnaire',
  },

  ROLE: {
    ALL: 'all',
  },

  COGNITO: {
    ATTRIBUTE_ACTIVATE: 'custom:activate',
  },
}

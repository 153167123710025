export const API_ERROR_CODE = {
  NOT_FOUND: 404,
  TIMEOUT_ERROR: 'TimeoutError',
  SYSTEM_ERROR: 'systemError',
  INVALID_PARAMETER: 'invalidParameter',
  UNAUTHORIZED: 401,
  CONFLICT: 409,
  SERVICE_UNAVAILABLE: 503,
  VERIFY_CODE_MISMATCH: 'CodeMismatchException',
  EMAIL_NOT_REGISTERED: 'UserNotFoundException',
  WRONG_PASSWORD: 'NotAuthorizedException',
  NETWORK_DISCONNECT_NAME: 'HttpErrorResponse',
  NETWORK_DISCONNECT_TEXT: 'Unknown Error',
  UNKNOWN_ERROR: 0,
}

export const API_ERROR_MESSAGE_RESPONSE = {
  SYSTEM_ERROR: 'システムエラーが発生しました。',
}

export const API_ERROR_TYPE = {
  NOT_FOUND: 'notFound',
  TIMEOUT_ERROR: 'TimeoutError',
  SYSTEM_ERROR: 'systemError',
  INVALID_PARAMETER: 'invalidParameter',
  UNAUTHORIZED: 'unauthorized',
  MUTUAL_EXCLUSION: 'mutualExclusion',
  VERIFY_CODE_MISMATCH: 'CodeMismatchException',
  NETWORK_DISCONNECT: 'networkDisconnect',
  SERVICE_UNAVAILABLE: 'serviceUnavailable',
}

export const VALIDATOR_PATTERN = {
  numberPattern: '^\\d+$',
  numberHalfsize: /^[0-9]+$/,
  textFullsize: /^[ぁ-んァ-ン一-龥]+$/,
  textFullWidthKaTaKaNa: /^[ァ-ン]+$/,
  passwordPattern: /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])[a-zA-Z\d@$!%*?&]*$/,
  sizeFile50Mb: 52428800,
  textKaTaKaNa: /^[ァ-ヶｦ-ﾟー]*$/,
  uppercaseAlphabet: /^[A-Z ]+$/,
}

<template>
  <teleport to="body">
    <transition name="fade">
      <div class="modal" v-show="isVisible">
        <div class="close-sec">
          <img src="img/close.svg" class="closebtn2" @click="close" />
        </div>
        <slot></slot>
        <!-- {{message}}（メッセージを表示します。） -->
        <div></div>
      </div>
    </transition>
  </teleport>
</template>

<script>
  // import Loading from "vue-loading-overlay";
  export default {
    props: {
      isVisible: {
        required: true,
        type: Boolean,
        default: false,
      },
      message: {
        required: false,
        type: String,
        default: '',
      },
    },
    emits: ['close'],
    components: {
      // Loading
    },
    data() {
      return {}
    },
    methods: {
      close() {
        this.$emit('close')
      },
    },
  }
</script>

<style scoped>
  .close-sec .closebtn2 {
    width: 28px;
  }
  .modal {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.58);
  }

  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 20px;
  }

  /* ローディングCSS */
  .ouro {
    position: relative;
    display: inline-block;
    height: 46px;
    width: 46px;
    margin: 1em;
    border-radius: 50%;
    background: none repeat scroll 0 0 #dddddd; /* ベースの色 - 可変 */
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
  }
  .ouro:after {
    content: '';
    position: absolute;
    top: 9px;
    left: 9px;
    display: block;
    height: 28px;
    width: 28px;
    background: none repeat scroll 0 0 #f2f2f2;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .ouro > span {
    position: absolute;
    height: 100%;
    width: 50%;
    overflow: hidden;
  }
  .left {
    left: 0;
  }
  .right {
    left: 50%;
  }
  .anim {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 999px;
    background: none repeat scroll 0 0 #508ec3; /* Ouroborosの色 - 可変 */
    opacity: 0.8;
    -webkit-animation: ui-spinner-rotate-left 3s infinite;
    animation: ui-spinner-rotate-left 3s infinite;
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
  }
  .left .anim {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .right .anim {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    left: -100%;
    -webkit-transform-origin: 100% 50% 0;
    transform-origin: 100% 50% 0;
  }
  /* v2 */
  .ouro2 .anim {
    -webkit-animation-delay: 0;
    animation-delay: 0;
  }
  .ouro2 .right .anim {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }
  /* v3 */
  .ouro3 .anim {
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-delay: 0s;
    animation-duration: 3s;
    animation-timing-function: linear;
  }
  .ouro3 .right .anim {
    -webkit-animation-name: ui-spinner-rotate-right;
    -webkit-animation-delay: 0;
    -webkit-animation-delay: 1.5s;
    animation-name: ui-spinner-rotate-right;
    animation-delay: 0;
    animation-delay: 1.5s;
  }
  /* round variation */
  .round .ouro:after {
    display: none;
  }
  /* double variation */
  .double .ouro:after {
    height: 13px;
    width: 13px;
    left: 7px;
    top: 7px;
    border: 10px solid #ddd;
    background: transparent;
    box-shadow: none;
  }
  @keyframes ui-spinner-rotate-right {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(180deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes ui-spinner-rotate-left {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes ui-spinner-rotate-right {
    0% {
      -webkit-transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(180deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes ui-spinner-rotate-left {
    0% {
      -webkit-transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: 0.1s ease-in-out;
  }

  .fade-leave-to,
  .fade-enter-from {
    opacity: 0;
  }
</style>

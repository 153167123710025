import { createStore } from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import * as session from '@/store/modules/session'
import * as form from '@/store/modules/form'
import * as master from '@/store/modules/master'

export const GETTER_TYPES = {
  GET_REFRESH_TOKEN: 'GET_REFRESH_TOKEN',
  GET_PROCESSING: 'GET_PROCESSING', // 全画面共通のloading
  GET_MY_STATION_PROCESSING: 'GET_MY_STATION_PROCESSING', // お気に入りステーションのloading
  GET_MAP_PROCESSING: 'GET_MAP_PROCESSING', // コンポーネント単位のloading
  GET_EV_PROCESSING: 'GET_EV_PROCESSING', // コンポーネント単位のloading
  GET_VEHICLE_PROCESSING: 'GET_VEHICLE_PROCESSING', // コンポーネント単位のloading
  GET_START_PROCESSING: 'GET_START_PROCESSING', // コンポーネント単位のloading
  GET_STOP_PROCESSING: 'GET_STOP_PROCESSING', // コンポーネント単位のloading
  GET_CONFIRM: 'GET_CONFIRM', // 確認ダイアログ
  GET_DIRTY: 'GET_DIRTY',
  GET_COLLAPSE: 'GET_COLLAPSE',
  GET_PAGE: 'GET_PAGE',
  GET_ERROR_MESSAGES: 'GET_ERROR_MESSAGES',
  GET_RESERVATION_DATA: 'GET_RESERVATION_DATA',
  GET_QUESTIONNAIRE_DATA: 'GET_QUESTIONNAIRE_DATA',
  GET_COGNITO_USER: 'GET_COGNITO_USER',
  GET_LIST_SERVICES: 'GET_LIST_SERVICES',
  GET_ALCOHOL_CONFIRMOR: 'GET_ALCOHOL_CONFIRMOR', // アルコールチェック確認者
  GET_RESERVATION_COMPLETE_MSG: 'GET_RESERVATION_COMPLETE_MSG', // 予約完了メッセージ
}

export const MUTATION_TYPES = {
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  SET_PROCESSING: 'SET_PROCESSING',
  SET_MY_STATION_PROCESSING: 'SET_MY_STATION_PROCESSING',
  SET_MAP_PROCESSING: 'SET_MAP_PROCESSING',
  SET_EV_PROCESSING: 'SET_EV_PROCESSING',
  SET_VEHICLE_PROCESSING: 'SET_VEHICLE_PROCESSING',
  SET_START_PROCESSING: 'SET_START_PROCESSING',
  SET_STOP_PROCESSING: 'SET_STOP_PROCESSING',
  SET_CONFIRM: 'SET_CONFIRM', // 確認ダイアログ
  SET_DIRTY: 'SET_DIRTY',
  SET_COLLAPSE: 'SET_COLLAPSE',
  SET_PAGE: 'SET_PAGE',
  SET_ERROR_MESSAGES: 'SET_ERROR_MESSAGES',
  SET_RESERVATION_DATA: 'SET_RESERVATION_DATA',
  SET_QUESTIONNAIRE_DATA: 'SET_QUESTIONNAIRE_DATA',
  SET_COGNITO_USER: 'SET_COGNITO_USER',
  SET_LIST_SERVICES: 'SET_LIST_SERVICES',
  SET_ALCOHOL_CONFIRMOR: 'SET_ALCOHOL_CONFIRMOR', // アルコールチェック確認者
  SET_RESERVATION_COMPLETE_MSG: 'GET_RESERVATION_COMPLETE_MSG', // 予約完了メッセージ
}

export default createStore({
  modules: {
    session,
    master,
    form,
  },

  state: {
    refreshToken: null,
    processing: false,
    my_station_processing: false,
    map_processing: false,
    ev_processing: false,
    vehicle_processing: false,
    start_processing: false,
    stop_processing: false,
    dirty: false,
    isCollapse: true,
    error_messages: [],
    page: 1,
    mode: process.env.NODE_ENV,
    confirm: {
      isOpen: false,
      message: '',
      title: '',
      isDialog: false,
      customOk: '',
      textOk: '',
      textCancel: '',
      resolve: (bool) => bool, // 追加
      reject: (bool) => bool, // 追加
    },
    reservation: null,
    questionnaire: null,
    cognitoUser: {},
    listServices: [],
    alcoholConfirmor: null,
    reservationCompleteMsg: null,
  },
  getters: {
    [GETTER_TYPES.GET_REFRESH_TOKEN](state) {
      return state.refreshToken
    },
    [GETTER_TYPES.GET_PROCESSING](state) {
      return state.processing
    },
    [GETTER_TYPES.GET_MAP_PROCESSING](state) {
      return state.map_processing
    },
    [GETTER_TYPES.GET_MY_STATION_PROCESSING](state) {
      return state.my_station_processing
    },
    [GETTER_TYPES.GET_EV_PROCESSING](state) {
      return state.ev_processing
    },
    [GETTER_TYPES.GET_VEHICLE_PROCESSING](state) {
      return state.vehicle_processing
    },
    [GETTER_TYPES.GET_START_PROCESSING](state) {
      return state.start_processing
    },
    [GETTER_TYPES.GET_STOP_PROCESSING](state) {
      return state.stop_processing
    },
    [GETTER_TYPES.GET_CONFIRM](state) {
      return state.confirm
    },
    [GETTER_TYPES.GET_DIRTY](state) {
      return state.dirty
    },
    [GETTER_TYPES.GET_COLLAPSE](state) {
      return state.isCollapse
    },
    [GETTER_TYPES.GET_PAGE](state) {
      return state.page
    },
    [GETTER_TYPES.GET_ERROR_MESSAGES](state) {
      return state.error_messages
    },
    [GETTER_TYPES.GET_RESERVATION_DATA](state) {
      return state.reservation
    },
    [GETTER_TYPES.GET_QUESTIONNAIRE_DATA](state) {
      return state.questionnaire
    },
    [GETTER_TYPES.GET_COGNITO_USER](state) {
      return state.cognitoUser
    },
    [GETTER_TYPES.GET_LIST_SERVICES](state) {
      return state.listServices
    },
    [GETTER_TYPES.GET_ALCOHOL_CONFIRMOR](state) {
      return state.alcoholConfirmor
    },
    [GETTER_TYPES.GET_RESERVATION_COMPLETE_MSG](state) {
      return state.reservationCompleteMsg
    },
  },
  mutations: {
    [MUTATION_TYPES.SET_REFRESH_TOKEN](state, refreshToken) {
      state.refreshToken = refreshToken
    },
    [MUTATION_TYPES.SET_PROCESSING](state, processing) {
      state.processing = processing
    },
    [MUTATION_TYPES.SET_MY_STATION_PROCESSING](state, my_station_processing) {
      state.my_station_processing = my_station_processing
    },
    [MUTATION_TYPES.SET_MAP_PROCESSING](state, map_processing) {
      state.map_processing = map_processing
    },
    [MUTATION_TYPES.SET_EV_PROCESSING](state, ev_processing) {
      state.ev_processing = ev_processing
    },
    [MUTATION_TYPES.SET_VEHICLE_PROCESSING](state, vehicle_processing) {
      state.vehicle_processing = vehicle_processing
    },
    [MUTATION_TYPES.SET_START_PROCESSING](state, start_processing) {
      state.start_processing = start_processing
    },
    [MUTATION_TYPES.SET_STOP_PROCESSING](state, stop_processing) {
      state.stop_processing = stop_processing
    },
    [MUTATION_TYPES.SET_CONFIRM](state, confirm) {
      state.confirm = confirm
    },
    [MUTATION_TYPES.SET_DIRTY](state, dirty) {
      state.dirty = dirty
    },
    [MUTATION_TYPES.SET_COLLAPSE](state, isCollapse) {
      state.isCollapse = isCollapse
    },
    [MUTATION_TYPES.SET_PAGE](state, page) {
      state.page = page
    },
    [MUTATION_TYPES.SET_ERROR_MESSAGES](state, error_messages) {
      state.error_messages = error_messages
    },
    [MUTATION_TYPES.SET_RESERVATION_DATA](state, reservation) {
      state.reservation = reservation
    },
    [MUTATION_TYPES.SET_QUESTIONNAIRE_DATA](state, questionnaire) {
      state.questionnaire = questionnaire
    },
    commitConfirmOpen(state, payload) {
      state.confirm = payload
    },
    commitResetConfirmState(state) {
      state.confirm = {
        isOpen: false,
        message: '',
        title: '',
        isDialog: false,
        customOk: '',
        textOk: '',
        textCancel: '',
        resolve: (bool) => bool,
        reject: (bool) => bool,
      }
    },
    [MUTATION_TYPES.SET_COGNITO_USER](state, cognitoUser) {
      state.cognitoUser = cognitoUser
    },
    [MUTATION_TYPES.SET_LIST_SERVICES](state, listServices) {
      state.listServices = listServices
    },
    [MUTATION_TYPES.SET_ALCOHOL_CONFIRMOR](state, alcoholConfirmor) {
      state.alcoholConfirmor = alcoholConfirmor
    },
    [MUTATION_TYPES.SET_RESERVATION_COMPLETE_MSG](state, reservationCompleteMsg) {
      state.reservationCompleteMsg = reservationCompleteMsg
    },
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState()],
  actions: {
    actionConfirmOpen({ commit }, payload) {
      // 1.Promiseインスタンスを生成
      return new Promise((resolve, reject) => {
        const option = {
          resolve,
          reject,
          ...payload,
        }
        // 2.payloadのデータと「resolve」「reject」をstateに保存
        commit('commitConfirmOpen', option)
      })
    },
    // 3.モーダル内の「OK」ボタンがクリックされたら「resolve」を実行させる。
    actionConfirmResolve({ commit, state }) {
      state.confirm.resolve(true) // 4.モーダルの呼び出し元に、実行結果を返す
      commit('commitResetConfirmState')
    },
    // 3.「キャンセル」ボタンなら「reject」を実行させる
    actionConfirmReject({ commit, state }) {
      state.confirm.reject(false) // 4.モーダルの呼び出し元に、実行結果を返す
      commit('commitResetConfirmState')
    },
  },
})

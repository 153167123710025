import * as Sentry from '@sentry/browser'

export default class ErrorTracking {
  static configureScope(session) {
    Sentry.configureScope((scope) => {
      /* eslint-disable */
      scope.setTag('carshareCorpId', CARSHARE_CORP_ID)
      scope.setUser({
        id: session.id,
        username: `${session.firstName} ${session.lastName}`,
        email: session.email,
      })
    })
  }

  static captureException(err) {
    Sentry.captureException(err)
  }

  static showReportDialog() {
    Sentry.showReportDialog()
  }

  static captureMessage(message) {
    Sentry.captureMessage(message)
  }
}

<template>
  <div id="root">
    <error-boundary id="app" v-cloak>
      <router-view />
      <transition name="fade">
        <app-confirm v-if="isOpen" />
      </transition>
    </error-boundary>
  </div>
</template>

<script>
  import { commonApi } from '@/module/Api'
  import { MASTER_MUTATION_TYPES } from '@/store/modules/master'

  export default {
    name: 'app',
    data() {
      return {
        state: {},
        apiError: false,
        isError: false,
        isCompleted: false,
      }
    },
    beforeCreate(){
      // 確認モーダルが残っていたら消す
      this.$store.dispatch('actionConfirmReject', false)
    },
    created() {},
    async mounted() {
      await this.setMasterInfo()
    },
    methods: {
      async setMasterInfo() {
        if (this.$options.name === 'app') {
          const response = await commonApi.getCorpParam()

          this.$store.commit(MASTER_MUTATION_TYPES.SET_CARSHARE_PARAM, response.data[0])
        }
      },
    },
    computed: {
      isOpen() {
        return this.$store.state.confirm.isOpen
      },
    },
  }
</script>

<style>
  .container {
    position: relative;
  }

  /*----- toast message -----*/
  .c-toast--success {
    background-color: #007ecb !important;
  }
  .v--default-css .c-toast {
    color: white !important;
  }
  /*-------------------------*/

  /* map上の不要なボタンを非表示 */
  .gmnoprint.gm-style-cc {
    display: none !important;
  }

  .gmnoprint {
    right: 0 !important;
  }

  /* 誤り報告ボタン非表示 */
  .gm-style-cc:nth-child(4) {
    display: none !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: 0.1s ease-in-out;
  }

  .fade-leave-to,
  .fade-enter-from {
    opacity: 0;
  }
</style>
